import { axios } from '../utils/axios';
import {
  PublishVersionType,
  PublishCountType,
  PublishVideoType,
  ContentListType,
  ContentDataType,
  ContentPlatformType,
  RepublishVideoType,
  VideoPlatformType,
} from '@/stores/ContentStore/types';

export interface IPublishCountParams {
  type: PublishVersionType;
  pidList?: number[];
}

export interface IPublishCountResponse {
  list: PublishCountType[];
}

export interface IContentListResponse {
  list: ContentListType[];
  nextCursor: number;
  total: number;
}

export interface IContentDataResponse {
  videoMetrics: {
    [key: number]: {
      sum: ContentDataType;
      platforms: ContentDataType[];
    };
  };
}

export interface IContentPlatformResponse {
  list: ContentPlatformType[];
  nextCursor: number;
}

export interface IVideoPlatformResponse {
  list: VideoPlatformType[];
  nextCursor: number;
}

const contentServices = {
  // 获取视频发布次数
  getPublishCount: (params: IPublishCountParams) =>
    axios.get<IPublishCountResponse, IPublishCountResponse>('/video/v1/worth', { params }),
  publishVideo: (params: PublishVideoType) => axios.post('/video/v1/original', params),
  getContentList: (cursor: number, size: number) =>
    axios.get<IContentListResponse, IContentListResponse>('/video/v1/original', {
      params: { cursor, size },
    }), // 获取内容视频列表
  getContentData: (vids: number[]) =>
    axios.get<IContentDataResponse, IContentDataResponse>('/spiderman/v2/videoMetrics', {
      params: { vids },
    }), // 获取内容视频数据
  getContentPlatformList: (originalId: number, cursor: number) =>
    axios.get<IContentPlatformResponse, IContentPlatformResponse>('/video/v1/publish', {
      params: { originalId, cursor },
    }), // 获取内容分发的平台列表
  republishVideo: (params: RepublishVideoType) => axios.post('/video/v1/publish', params), // 转发视频到其他平台
  getPlatformVideoInfoList: (originalId: number, cursor: number) =>
    axios.get<IVideoPlatformResponse, IVideoPlatformResponse>('/video/v1/video', {
      params: { originalId, cursor },
    }), // 获取该视频分发到每个平台的视频信息
  getVideosOfPlatform: (platformId: number, cursor: number, size: number) =>
    axios.get<IVideoPlatformResponse, IVideoPlatformResponse>(
      `/video/v1/video/platform/${platformId}`,
      {
        params: { cursor, size },
      }
    ), // 获取该平台所有的视频
};

export default contentServices;
