import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import contentServices from '@/services/contentServices';
import {
  DraftType,
  PublishVersionType,
  PublishCountType,
  PublishVideoType,
  ContentListType,
  ContentDataType,
  RepublishVideoType,
} from './types';
import { DownloadBatchFiles } from '../MaterialStore/types';
import dayjs from 'dayjs';

export default class ContentStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'ContentStore',
      properties: ['draft', 'showUploadTip'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  showUploadTip: boolean = false;
  setShowUploadTip(show: boolean) {
    this.showUploadTip = show;
  }

  draft: DraftType = {};
  saveDraft(d: DraftType) {
    this.draft = d;
  }

  checkDraft() {
    if (
      this.draft.file ||
      this.draft.platformIds ||
      this.draft.title ||
      this.draft.coverFile ||
      this.draft.intro ||
      this.draft.subtitle ||
      this.draft.releaseTime
    ) {
      return true;
    }
    return false;
  }

  clearDraft() {
    this.draft = {};
  }

  // 获取视频发布次数
  async getPublishCount(type: PublishVersionType, pidList?: number[]) {
    const params = {
      type,
      pidList,
    };
    const res = await contentServices.getPublishCount(params);
    return Promise.resolve(res.list);
  }

  async publishVideo(params: PublishVideoType) {
    const res = await contentServices.publishVideo(params);
    return Promise.resolve(res);
  }

  async getContentList(cursor: number) {
    const res = await contentServices.getContentList(cursor, this.pageSize);
    if (cursor === 0) {
      this.clearContentList();
    }
    this.saveContentList(res.list, res.total);
    return Promise.resolve(res);
  }

  clearContentList() {
    this.contentList = [];
    this.totalCount = 0;
    this.hasMore = true;
  }

  pageSize = 100;
  hasMore = true;
  totalCount = 0;
  contentList: ContentListType[] = [];
  saveContentList(list: ContentListType[], total: number) {
    // 合并当前数据和新请求的数据,并筛选出重复数据
    const newList = this.contentList.concat(
      list.filter((itemB) => !this.contentList.some((itemA) => itemA.id === itemB.id))
    );
    this.contentList = newList;
    this.totalCount = total;
    this.hasMore = newList.length < total;
    this.sortContentList();
    // 晒选出未填充视频数据的item
    const unFillDataList = newList.filter((item) => !item.sum).map((item) => item.id);
    if (unFillDataList.length > 0) {
      this.getContentData(unFillDataList);
    }
  }

  saveCoverList(list: DownloadBatchFiles[]) {
    const newList = this.contentList.map((itemA) => {
      const itemB = list.find((itemB) => itemB.id === itemA.coverId);

      if (itemB) {
        return { ...itemA, coverUrl: itemB.url };
      } else {
        return itemA;
      }
    });
    this.contentList = newList;
  }

  async getContentData(vids: number[]) {
    const res = await contentServices.getContentData(vids);
    this.saveContentData(res.videoMetrics);
    return Promise.resolve(res);
  }

  saveContentData(dataMap: {
    [key: number]: { sum: ContentDataType; platforms: ContentDataType[] };
  }) {
    const newList = this.contentList.map((item) => {
      if (dataMap[item.id]) {
        return { ...item, ...dataMap[item.id] };
      } else {
        return item;
      }
    });
    this.contentList = newList;
  }

  // 排序视频列表
  sortContentList(sort = 'default') {
    const array = this.contentList.sort((a, b) => {
      if (sort === 'default') {
        return dayjs(a.createdAt).isBefore(dayjs(b.createdAt))
          ? 1
          : dayjs(a.createdAt).isSame(dayjs(b.createdAt))
          ? 0
          : -1;
      } else if (sort === 'plays') {
        return Number(b.sum?.views || '0') - Number(a.sum?.views || '0');
      } else {
        return Number(b.sum?.likes || '0') - Number(a.sum?.likes || '0');
      }
    });
    this.contentList = array;
  }

  // 获取视频发布的平台列表
  async getContentPlatformList(id: number, cursor: number) {
    const res = await contentServices.getContentPlatformList(id, cursor);
    return Promise.resolve(res);
  }

  // 转发视频到其他平台 (一键发布)
  async republishVideo(params: RepublishVideoType) {
    const res = await contentServices.republishVideo(params);
    return Promise.resolve(res);
  }

  // 获取每个平台的视频详情信息
  async getPlatformVideoInfoList(id: number, cursor: number) {
    const res = await contentServices.getPlatformVideoInfoList(id, cursor);
    return Promise.resolve(res);
  }

  // 获取该平台所有的视频
  async getVideosOfPlatform(platformId: number, cursor: number, size: number) {
    const res = await contentServices.getVideosOfPlatform(platformId, cursor, size);
    return Promise.resolve(res);
  }

  // 清除Store数据
  clearStore() {
    this.showUploadTip = false;
    this.clearDraft();
    this.clearContentList();
  }
}
